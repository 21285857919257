.input-container {
    position: relative;
    width: 100%;
    border: 1px solid var(--blue-400);
    display: flex;
    align-items: center;
    padding-right: 10px;
    transition: all 0.3s ease;
    border-radius: 4px;
    height: 40px;
}

.input-container i,
.input-container svg {
    font-size: 20px;
    margin-left: 10px;
    transition: all 0.3s ease;
}

.input-container.focused i,
.input-container.focused svg {
    font-size: 18px;
}

.input-container input {
    border: none;
    outline: none;
    width: 100%;
    padding: 5px;
}

.input-container input::placeholder {
    opacity: 0.6;
}
