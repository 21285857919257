$image-size: 45px;
.control-group {
    padding: 16px 10px;
    border-bottom: 1px solid var(--gray-100);
    cursor: pointer;
    &:hover {
        background-color: var(--gray-50);
    }

    .control-group-leading {
        .image {
            img {
                height: $image-size;
                width: $image-size;
                object-fit: cover;
            }
        }
        .name-section {
            .title {
                margin-bottom: 3px;
                .name {
                    font-size: 16px;
                    color: var(--gray-900);
                    font-weight: 300;
                    line-height: 1;
                }
                .type {
                    font-weight: 400;
                    font-size: 14px;
                    color: var(--gray-300);
                    margin-bottom: 1px;
                    line-height: 1;
                }
                .badge {
                    svg {
                        color: var(--blue-500);
                    }
                }
            }
            .username {
                font-size: 13px;
                line-height: 1.3;
                font-weight: 200;
            }
        }
    }

    .control-group-trailing {
        .rating-icon {
            font-size: 14px;
            color: #ffac55;
        }
        .text {
            font-size: 13px;
            color: var(--gray-500);
        }
    }

    .pricing {
        .pricing-icon {
            font-size: 14px;
            color: var(--gray-600);
        }
        .text {
            font-size: 13px;
            color: var(--gray-600);
            font-weight: 400;
        }
    }
}
