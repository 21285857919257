.room-card-plus-container {
    padding: 10px;
    display: grid;
    // align-items: center;
    grid-template-columns: auto 40px;
    margin-right: -23px;
    // overflow-x: hidden;

    .controls-container {
        position: relative;
        // overflow: hidden;

        .action-menu {
            height: 55px;
            width: 100%;
            padding: 0 16px;
            display: flex;
            justify-content: flex-end;
            transform: translateX(100%);
            transition: transform 0.25s linear;

            .actions-container {
                width: fit-content;
                display: flex;
                gap: 5px;
                // border: 1px solid var(--gray-200);
                border-radius: 6px;
                padding: 5px;
            }

            &.is-menu-open {
                transform: translateX(0);
            }
        }

        .action-menu-panel {
            margin: 0px 16px 0 45px;
            // height: 55px;

            .write-action-menu {
                position: relative;
                height: 55px;
                margin-right: 5px;

                .input-element {
                    border-radius: 4px;
                    border: 1px solid var(--gray-400);
                    height: 100%;
                    padding: 10px 10px;
                    outline: none;
                    background-color: white;

                    &:focus {
                        outline: none;
                        border-color: var(--blue-500);
                    }
                }

                .input-action {
                    position: absolute;
                    bottom: 5px;
                    right: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // gap: 2px;

                    .input-actions-button {
                        height: 30px;
                        width: 30px;
                        border-radius: 4px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .icon {
                            font-size: 22px;
                            color: var(--gray-500);
                            stroke-width: 1px;
                        }
                    }
                }

                .input-tag-menu {
                    max-height: 100px;
                    min-height: 45px;
                    background-color: white;
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 100%;
                    z-index: 10;
                    border: 1px solid var(--gray-100);
                    border-bottom: none;
                }
            }
        }

        .action-button {
            cursor: pointer;
            border: 1px solid var(--gray-400);
            background-color: white;
            color: var(--gray-900);
            display: flex;
            gap: 10px;
            align-items: center;
            height: 40px;
            padding: 0 14px;
            border-radius: 6px;

            .icon {
                stroke-width: 1px;
            }

            &.active {
                border: 1px solid var(--blue-400);
                background-color: var(--blue-50);
            }

            svg {
                font-size: 24px;
            }
        }
    }

    .plus-btn {
        margin-top: 5px;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.2s linear 0.05s;

        &:hover {
            opacity: 0.8;
        }

        &.is-menu-open {
            transform: rotateZ(-45deg);
        }

        .plus-icon {
            font-size: 36px;
        }
    }
}

.video-recorder {
    width: 550px;
    height: 400px;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    .video-preview {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .video-recorder-header {
        .recording-pulse {
            position: relative;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: rgb(255, 86, 86);
            animation: pulse 1s ease-in-out infinite;
        }
    }

    .video-recorder-footer {
        button {
            transition: transform 0.3s linear;
            &:hover {
                transform: scale(1.15);
            }
        }
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.5);
        opacity: 0.5;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
