@import "mixins/grid";

.container {
    width: 100%;
    background-color: #ffffff;
    border: 1px solid var(--gray-100);
    border-radius: 4px;
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}

.wrapper {
    bottom: 10px;
    margin-top: 10px;
    left: 5px;
    right: 5px;
    width: 100%;
    z-index: 1000;
    position: fixed;
    display: grid;
    grid-template-columns: 30px auto;
    align-items: center;
    gap: 6px;

    .icon {
        color: var(--gray-500);
    }

    @include media-gt-phone {
        width: 431px;
        bottom: 100px;
        left: 38%;
        transform: translateX(-50%);
    }
}

.circle {
    width: 35px;
    height: 35px;
    background-color: black;
    border-radius: 50%;
}

.selectAi {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    .placeholder {
        color: var(--gray-900);
        font-weight: 300;
    }
}

.controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--gray-500);

    &-1 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // margin: 0 10px;

        svg {
            font-size: 18px !important;
            stroke-width: 0px;
        }

        .notification-section {
            position: relative;

            .dot {
                position: absolute;
                bottom: 2px;
                right: 2px;
                background-color: var(--red-400);
                height: 8px;
                width: 8px;
                border-radius: 100px;
                display: inline-block;
            }
        }
    }
}

.group-btn {
    margin: 0 10px;
    // width: 90px;
    border: 1px solid var(--primary-base);
    padding: 5px 12px;
    border-radius: 4px;
    color: white;
    background-color: var(--primary-base);

    &:hover {
        background-color: var(--gray-800);
    }
}

.dropdown-content {
    background-color: #ffffff;
    border: 1px solid var(--gray-100);
    border-radius: 4px;
    padding: 10px 10px 0;
    //width: 300px;
    position: absolute;
    top: calc(100% + 10px);
    left: 37px;
    right: 0;
    transform: translateX(-50%);
    transition:
        opacity 0.3s ease,
        transform 0.3s ease;
    opacity: 0;
    transform: translateY(-10px);
    z-index: 10;
    // display: none;
    align-items: start;

    .grow-ai-entities {
        position: relative;

        .ai-entities-btn {
            position: absolute;
            top: 12px;
            right: 8px;
            height: 20px;
            width: 20px;

            .icon {
                font-size: 16px !important;
                stroke-width: 0px;
                color: var(--gray-500);
            }
        }
    }

    //.form-group {
    //    padding: 6px 8px;
    //    border: 1px solid var(--gray-100);
    //    border-radius: 8px;
    //    margin-bottom: 12px;
    //
    //    .form-label {
    //        font-weight: 400;
    //        font-size: 12px;
    //        margin-bottom: 14px;
    //        color: var(--gray-800);
    //    }
    //}

    .info-container {
        margin: 16px 0 20px;
        padding: 0 12px;

        .info-title {
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 5px;
            display: flex;
            gap: 10px;
            align-items: center;

            .icon {
                font-size: 16px;
            }
        }

        .info-text {
            font-size: 14px;
            font-weight: 300;
            line-height: 1.3;
            color: var(--gray-600);
        }
    }
}

.wrapper .dropdown-content {
    display: block;
    opacity: 1;
    transform: translateY(0);
}

.entities-options {
    padding: 16px 10px 10px;

    .option-tile {
        display: flex;
        margin-bottom: 16px;
        gap: 12px;

        .icon {
            padding-left: 3px;

            svg {
                stroke-width: 0.1px;
                color: var(--gray-400);
            }
        }

        .title {
            font-size: 16px;
            font-weight: 300;
            line-height: 1;
            margin-bottom: 6px;
        }

        .subtitle {
            font-size: 13px;
            margin: 0;
            font-weight: 300;
            line-height: 1.3;
            color: var(--gray-900);
        }

        .option-tile-link {
            color: var(--blue-500);
            display: inline-block;
            padding: 0px 6px 2px 0;
        }
    }
}

.form-group {
    position: relative;
    border-radius: 6px;
    border: 1px solid var(--gray-200);
    margin-top: 20px;
    padding: 24px 10px 10px;

    .form-label-header {
        position: absolute;
        top: -12px;
        width: 100%;
        padding: 0px;

        .form-label {
            padding: 2px 10px;
            font-size: 14px;
            border: 1px solid var(--gray-300);
            border-radius: 4px;
            width: fit-content;
            font-weight: 300;
            background-color: white;
        }
    }

    .arin {
        display: flex;
        gap: 15px;
        align-items: center;
        height: 55px;
        padding: 0 14px;
        margin-top: 22px;

        .arin-image {
            img {
                height: 40px;
                width: 40px;
                border-radius: 50%;
                object-fit: cover;
            }
        }

        .profile {
            .arin-name {
                font-size: 15px;
            }

            .arin-username {
                font-size: 14px;
                color: var(--gray-500);
            }
        }
    }
}
