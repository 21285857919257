.genealogy-container {
    &__sidebarLoader {
        height: 95%;
        display: flex;
        flex-direction: column;
        width: 25%;
        background: #fff;
        position: fixed;
        left: 24px;
        top: 2.5%;
        overflow-y: hidden;
        max-width: 300px;
        border-radius: 12px;
        z-index: 1000;
        padding: 20px 12px 8px;
        border: 1px solid #cdcdcd;
    }

    &__topSectionLoader {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & div {
            background: #f0f0f0;
            animation: pulse 1.4s infinite;
            height: 46px;
            border-radius: 8px;
            width: 60%;
        }
    }

    &__bottomSectionLoader {
        background: #f0f0f0;
        height: 46px;
        animation: pulse 1.4s infinite;
        width: 100%;
        margin-top: 16px;
        border-radius: 8px;
    }

    &__resultsLoader {
        border-top: 1px solid #a8a8a8;
        display: flex;
        flex-direction: column;
        padding-bottom: 12px;
        height: 68%;
        padding-top: 24px;
        margin-top: 16px;
        gap: 12px;
    }

    &__item {
        width: 100%;
        height: 46px;
        background: #f0f0f0;
        animation: pulse 1.4s infinite;
        display: flex;
        justify-content: flex-start;
        border-radius: 8px;
    }
}

@keyframes pulse {
    0%,
    100% {
        background: #f0f0f0;
    }
    25% {
        background: #f0f0f0da;
    }
    50% {
        background: #f0f0f0a8;
    }
}
