.wave {
    background-image: repeating-linear-gradient(
        45deg,
        #e0e0e0,
        #e0e0e0 45px,
        #d0d0d0 45px,
        #d0d0d0 70px
    );
    animation: wave-animation 1s infinite linear;
}

@keyframes wave-animation {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 50px 0;
    }
}
