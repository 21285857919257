.brain-activity-arin {
    position: relative;
    min-height: 65px;
    border-radius: 6px;
    border: 1px solid var(--gray-100);
    margin-top: 20px;

    .brain-activity-header {
        position: absolute;
        top: -18px;
        width: 100%;
        padding: 0 16px;

        .leading {
            .title {
                font-weight: 300;
                font-size: 16px;
            }
            svg {
                font-size: 18px;
            }
        }

        .trailing {
            button {
                padding: 2px 10px;
                font-size: 14px;
                border-radius: 4px;
            }

            &:not(.chakra-styled) button {
                border: 1px solid var(--gray-300);
            }
        }
    }

    .activity-inner {
        display: grid;
        grid-template-rows: 70px auto;
        height: 100%;
    }

    .arin {
        display: flex;
        gap: 15px;
        align-items: center;
        justify-content: space-between;
        height: 55px;
        padding: 0 14px;
        margin: 16px 0 10px;

        .arin-image {
            img {
                height: 40px;
                width: 40px;
                border-radius: 50%;
                object-fit: cover;
            }
        }

        .profile {
            .arin-name {
                font-size: 15px;
                line-height: 1.3;
            }
            .arin-username {
                font-size: 14px;
                color: var(--gray-500);
                line-height: 1;
            }
        }
    }

    .dropdown-menu {
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        border-radius: 0 0 4px 4px;
        border: 1px solid var(--gray-100);
        max-height: 400px;
        overflow: auto;
        z-index: 1;
        background-color: white;
        text-align: left;
        transition: max-height 0.3s ease-in-out;
        display: block;
        text-align: left;
        color: var(--gray-700);

        > div {
            padding: 5px 10px;
        }
    }

    .persona-select {
        position: relative;
    }
}

.thoughts-container {
    position: relative;
    padding: 16px 14px 16px 20px;

    .thoughts-wrapper {
        border-top: 1px solid var(--gray-100);
        padding: 10px 0 0;
    }
    .thoughts-header {
        position: absolute;
        top: 0;
        padding: 5px 5px;
        background-color: white;
        font-size: 14px;
        font-weight: 400;
    }
    .thoughts-decription {
        padding: 5px 5px;
        font-size: 13px;
        font-weight: 300;
    }
}

.thoughts-sequence-container {
    position: relative;
    padding: 16px 14px 16px 14px;

    .thoughts-wrapper {
        border: 1px solid var(--blue-100);
        background-color: var(--blue-50);
        padding: 20px 0 0;
    }
    .thoughts-proper {
        max-height: 410px;
        overflow-y: auto;
    }
    .thoughts-header {
        position: absolute;
        top: 0;
        left: 20px;
        right: 24px;
        padding: 5px 5px;
        font-size: 14px;
        font-weight: 300;
        .header-tag {
            border: 1px solid var(--blue-100);
            background-color: #fff;
            padding: 2px 4px;
            border-radius: 3px;
            max-width: 200px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-weight: 300 !important;
        }
    }

    .cell {
        padding: 5px 20px 20px;
        margin-top: 20px;
        &:first-of-type {
            margin-top: 0;
        }
        .cell-item {
            position: relative;
            padding: 0 0 16px 0;

            &::before {
                content: "";
                position: absolute;
                top: 16px;
                left: 8px;
                bottom: 0;
                border-left: 1px solid var(--gray-500);
            }

            // &::after {
            //     border-left: 2px solid var(--gray-200);
            //     bottom: -35px;
            //     content: "";
            //     left: -3px;
            //     position: absolute;
            //     top: 0;
            // }

            .cell-item-head {
                .cell-item-title {
                    font-size: 15px;
                }
                .icon-box {
                    .icon {
                        color: var(--gray-700);
                    }
                }
            }

            .cell-item-description {
                padding: 0 0 0 20px;
                font-weight: 300;
            }
        }
    }
}

.chain-sequence-container {
    position: relative;
    padding: 16px 14px 16px 14px;
    // height: 410px;
    // overflow-y: auto;

    .chain-wrapper {
        border: 1px solid var(--blue-100);
        background-color: var(--blue-50);
        padding: 20px 0 0;
    }
    .chain-proper {
        max-height: 410px;
        overflow-y: auto;
    }
    .chain-header {
        position: absolute;
        top: 0;
        left: 20px;
        right: 24px;
        padding: 5px 5px;
        font-size: 14px;
        font-weight: 300;
        .header-tag {
            border: 1px solid var(--blue-100);
            background-color: #fff;
            padding: 2px 4px;
            border-radius: 3px;
            font-weight: 300 !important;
        }
    }

    .cell-body {
        .cell-item {
            position: relative;
            padding: 0 0 16px 0;

            &::before {
                content: "";
                position: absolute;
                top: 16px;
                left: 8px;
                bottom: 0;
                border-left: 1px solid var(--gray-500);
            }

            .cell-item-head {
                cursor: pointer;
                .cell-item-title {
                    font-size: 15px;
                }
                .icon-box {
                    .icon {
                        color: var(--gray-700);
                    }
                }

                &:hover {
                    .unchain-btn {
                        display: block;
                    }
                }
            }

            .cell-item-description {
                padding: 0 0 0 20px;
                font-weight: 300;
            }
        }
    }
}
