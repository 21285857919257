.control-group-notification {
    padding: 16px 10px;
    border-bottom: 1px solid var(--gray-100);
    &.unread {
        background-color: var(--gray-50);
    }
    &:last-child {
        border: none;
    }
    .leading {
        .title {
            font-size: 16px;
            color: #000;
            font-weight: 300;
        }
        .subtitle {
            font-size: 14px;
            color: var(--gray-900);
            font-weight: 300;
            line-height: 1.3;
            .link {
                color: var(--blue-500);
            }
        }
    }

    .action {
        .btn {
            height: 40px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            &.accept-btn {
                .icon {
                    color: var(--blue-600);
                }
            }
            &.reject-btn {
                .icon {
                    color: var(--red-600);
                }
            }
            .icon {
                font-size: 20px;
            }
        }
    }
}
