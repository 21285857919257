:root {
    --theme-bg: #fff;
    /*
    ==== PRIMARY COLROS =======
     */
    --primary-base: #1c1c1c;
    /*
    ==== GRAY =======
     */
    --gray-50: #f7f7f8;
    --gray-100: #ececf1;
    --gray-200: #d9d9e3;
    --gray-300: #c5c5d2;
    --gray-400: #acacbe;
    --gray-500: #8e8ea0;
    --gray-600: #6e6e80;
    --gray-700: #565869;
    --gray-800: #353740;
    --gray-900: #202123;
    /**
    ======== GREEN ==========
     */
    --green-50: #b7efc5;
    --green-100: #92e6a7;
    --green-200: #6ede8a;
    --green-300: #4ad66d;
    --green-400: #2dc653;
    --green-500: #25a244;
    --green-600: #208b3a;
    --green-700: #1a7431;
    --green-800: #155d27;
    --green-900: #10451d;
    /**
    ======== BLUE ==========
     */
    --blue-50: #eef6fd;
    --blue-100: #bbdefb;
    --blue-200: #90caf9;
    --blue-300: #64b5f6;
    --blue-400: #42a5f5;
    --blue-500: #2196f3;
    --blue-600: #1e88e5;
    --blue-700: #1976d2;
    --blue-800: #1565c0;
    --blue-900: #0a47a6;
    /**
    ======== RED ==========
     */
    --red-50: #ffebee;
    --red-100: #ffcdd2;
    --red-200: #ef9a9a;
    --red-300: #e57373;
    --red-400: #ef5350;
    --red-500: #f44336;
    --red-600: #e53935;
    --red-700: #d32f2f;
    --red-800: #c62828;
    --red-900: #b71c1c;
}
