.room-card {
    position: relative;
    background-color: white;
    border: 1px solid var(--gray-100);
    border-radius: 12px;
    width: 280px;
    cursor: pointer;
    // min-height: 380px;
    height: fit-content;
    box-shadow: 2px 4px 24px 0 rgba(0, 0, 0, 0.12);
    // clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);

    &:hover {
        border-color: var(--blue-500);
    }

    .attention-box {
        height: 3px;
        border-radius: 100px;
        position: absolute;
        top: -3px;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;

        .attention-banner {
            height: 100%;
            width: 80%;
            &.red {
                background-color: var(--red-500);
            }
            &.green {
                background-color: var(--green-500);
            }
            &.blue {
                background-color: var(--blue-500);
            }
        }
    }

    .card-header {
        background-color: var(--gray-100);
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;

        .card-owner {
            display: flex;
            align-items: center;
            height: 40px;
            padding: 0 20px;

            img {
                height: 20px;
                width: 20px;
                margin-right: 6px;
            }

            .card-owner-name {
                font-size: 16px;
                font-weight: 400;
            }
        }
    }

    .card-header-content {
        background-color: white;
        position: relative;
        padding: 20px 20px 20px;
        min-height: 145px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;

        .actions-col {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            padding-bottom: 15px;

            .icon {
                color: var(--gray-600);
            }
        }

        .notification-badge {
            position: absolute;
            top: 16px;
            right: 14px;
            height: 24px;
            width: 24px;
            border-radius: 50%;
            background-color: #ef3f3f;
            color: white;
            font-weight: 500;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
        }

        .card-title {
            font-size: 20px;
            font-weight: 400;
            color: #5e5e5e;
            line-height: 1;
            margin-bottom: 6px;
            display: flex;
            align-items: flex-end;
            gap: 6px;
            .arin-tag {
                font-weight: 400;
                font-size: 14px;
                color: var(--gray-300);
                margin-bottom: 1px;
                line-height: 1;
            }
        }

        .owner-text {
            line-height: 1;
            font-size: 14px;
            font-weight: 300;
            color: var(--gray-900);

            .name {
                color: var(--blue-900);
            }
        }

        .card-description {
            margin-top: 11px;
            font-size: 14px;
            font-weight: 300;
            line-height: 1.3;
            color: var(--gray-900);
            cursor: pointer;

            &.minimize {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }

        .room-participants {
            display: grid;
            grid-template-columns: 30px auto;
            padding: 2px;
            border-radius: 100px;
            background-color: var(--gray-50);

            .persona-list {
                display: flex;
                gap: 2px;

                .name {
                    font-size: 12px;
                    color: var(--gray-400);
                }
            }
        }
    }

    .card-body {
        // padding-top: 40px;
        display: flex;

        .persona-preview {
            display: inline-block;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            //border-top-right-radius: 8px;
            //border-top-left-radius: 8px;
            // border-radius: 8px;
            // margin-right: -14px;
            // box-shadow: 0px -6px 24px 0 rgba(0, 0, 0, 0.4);
            width: 25%;
            height: 250px;
            overflow: hidden;
            transition: width 0.3s linear;

            .persona-overlay {
                opacity: 0;
                background-color: rgba(0, 0, 0, 0.4);
                height: 100%;
                //border-top-right-radius: 20px;
                //border-top-left-radius: 20px;
                color: white;
                padding: 14px 16px 24px;
                transition: opacity 0.2s linear;
                transition-delay: 0.2s;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;

                //.overlay-large {
                //    position: relative;
                //    border-radius: 20px;
                //}

                .price {
                    display: inline-block;
                    padding: 3px 8px;
                    font-size: 12px;
                    border-radius: 6px;
                    border: 1px solid #fff;
                    color: white;
                    margin-bottom: 3px;
                }

                .title {
                    font-size: 15px;
                    font-weight: 500;
                    margin: 0;
                    width: 100%;

                    &.with-check {
                        display: grid;
                        grid-template-columns: auto 20px;
                        align-items: center;

                        .icon {
                            font-size: 14px;
                            color: white;
                        }
                    }
                }

                .description {
                    font-size: 14px;
                    margin: -4px 0 4px;
                }

                .role-container {
                    .role-container-header {
                        cursor: pointer;
                        display: flex;
                        gap: 4px;
                    }
                    .role {
                        font-size: 14px;
                        display: flex;
                        align-items: center;
                        gap: 6px;
                    }
                }

                .overlay-large {
                    position: relative;
                    height: 100%;

                    .persona-count {
                        font-size: 12px;
                        font-weight: 400;
                        position: absolute;
                        bottom: 0px;
                    }
                }
            }

            &.last-card {
                .persona-overlay {
                    border-bottom-left-radius: 12px;
                    border-bottom-right-radius: 12px;
                }
            }

            &:first-child {
                border-top-left-radius: 8px;
            }

            &:last-child {
                // height: 165px;
                margin-right: 0;
                border-top-right-radius: 8px;
                //border-bottom-right-radius: 8px;
                //border-bottom-left-radius: 12px;
                //border-bottom-right-radius: 12px;
            }
        }
    }

    .card-footer {
        height: 55px;
        display: flex;
        align-items: center;
        grid-column-gap: 10px;
        justify-content: center;
        padding: 8px;

        .settings-container {
            width: 49%;
            height: 100%;

            .settings-btn {
                height: 100%;
                width: 100%;
                text-align: center;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;

                &.with-text {
                    justify-content: flex-end;
                    gap: 0px;
                    padding-right: 22px;

                    .text {
                        font-weight: 300;
                        color: var(--gray-900);
                        display: inline-block;
                        min-width: 21px;
                    }
                }

                //&:hover {
                //    background-color: rgba(0, 0, 0, 0.05);
                //}

                .icon {
                    display: inline;
                    font-size: 22px;
                    // color: var(--gray-900);
                    stroke-width: 1px;
                }
            }

            .settings-dropdown {
                position: absolute;
                right: calc(100% + 8px);
                min-height: 40px;
                max-height: 300px;
                overflow-y: auto;
                background-color: white;
                width: 280px;
                border-radius: 8px;
                bottom: 0;
                border: 1px solid var(--gray-100);
                box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
                padding: 10px 12px;
                &.sm {
                    width: 100px;
                }
            }
        }
    }
}

.rate-section {
    .title {
        font-size: 16px;
        font-weight: 300;
        line-height: 1.3;
    }
    .subtitle {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.3;
    }
    .form {
        margin-top: -4px;
    }
}

.flag-form-section {
    gap: 10px;
    .color-input {
        position: relative;
        height: 35px;
        width: 35px;
        border-radius: 50%;
        &.active {
            outline: 2px solid var(--primary-base);
        }
        &.red {
            background-color: var(--red-500);
        }
        &.green {
            background-color: var(--green-500);
        }
        &.blue {
            background-color: var(--blue-500);
        }
        &.no-color {
            background-color: white;
            border: 0.5px solid var(--gray-500);
            // write css code to make the border inside
            .cross {
                height: 100%;
                width: 2px;
                background-color: var(--red-500);
                transform: rotateZ(45deg);
            }
        }
    }
}
