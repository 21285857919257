html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
}

.canvas-container {
    height: 100vh;
    width: 100vw;
}

.genealogy-link_button {
    color: white;
    position: fixed;
    bottom: 10%;
    right: 5%;
    display: flex;
    gap: 8px;
}
