.genealogy-container {
    width: 100%;

    &__body {
        height: 100vh;
        width: 100vw;
        display: flex;
        padding: 40px;
    }

    &__sidebar {
        height: 90%;
        display: flex;
        flex-direction: column;
        background: #fff;
        position: fixed;
        left: 24px;
        top: 6%;
        overflow-y: hidden;
        width: 300px;
        border-radius: 12px;
        z-index: 1000;
        padding: 8px 20px;
        border: 1px solid #cdcdcd;
    }

    &__main {
        position: relative;
        margin-left: -15rem;
        display: flex;
        width: unset;
        gap: 120px;

        & div {
            display: flex;
            justify-content: center;
            margin-top: -14rem;
            align-items: center;
        }

        & svg {
            height: 100vh;
            pointer-events: none;
            transform: translate(-40%, -50%);
        }

        & .rd3t-tree-container {
            width: 100%;
            height: 100vh;
        }

        & .rd3t-link:nth-child(1) {
            display: none;
        }
    }

    &__backarrow {
        position: fixed;
        left: 24px;
        top: 1%;
        z-index: 1000;
        font-size: 32px;
    }

    &__detailsCard {
    }

    // All calendar classes
    .dateRange-container {
        position: absolute;
        top: 8%;
        z-index: 1000;
        left: 360px;
        animation: fadeInLeft 0.8s forwards;
    }

    .react-daterange-picker__wrapper {
        display: flex;
        gap: 16px;
        background-color: #fff;
        padding: 8px 16px;
        border: 1px solid #e4e4e4;
        border-radius: 6px;
        font-size: 16px;
    }

    .react-calendar {
        background: #fff;
        padding: 12px;
        margin-top: 8px;
        border: 1px solid #e4e4e4;
        border-radius: 8px;

        &__navigation {
            display: flex;
        }

        &__navigation__next-button,
        &__navigation__prev-button {
            margin: 0 8px;
        }

        &__tile {
            &:hover {
                background-color: #919191dd;
                color: #fff;
            }
        }

        &__tile--active {
            background-color: #000;
            color: #fff;
        }
    }
}

.search-container {
    display: flex;
    flex-direction: column;
    gap: 6px;

    &__toolbar {
        width: 100%;
        display: flex;
        margin: 6px 0;
        gap: 18px;
        justify-content: space-between;

        & button {
            width: 50%;
        }
    }

    &__topSection {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        flex-direction: column;

        & h2 {
            font-size: 24px;
            font-weight: 600;
            width: 100%;
        }

        & .timelineButton {
            border-radius: 8px;
            padding: 8px;
            color: #fff;
            justify-content: center;
            align-items: center;
            display: flex;
            background: #000;
            gap: 8px;
        }

        & button {
            align-items: center;
            border-bottom: 1px solid #bababa;
            display: flex;
            justify-content: space-between;
            margin-top: 8px;
            height: 32px;

            span:first-of-type {
                font-size: 16px;
                margin-right: 10px;
            }

            lord-icon {
                margin-top: 2px;
            }
        }
    }

    &__bottomSection {
        border-bottom: 1px solid grey;
        width: 100%;
        display: flex;
        margin-top: 12px;
        position: relative;

        & input:focus {
            outline: none;
        }
    }

    &__results {
        border: none;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        padding-bottom: 12px;
        height: 68%;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
        }
    }

    &__item {
        width: 100%;
        border-bottom: 1px solid #a8a8a8;
        padding: 8px 12px;
        display: flex;
        justify-content: flex-start;
    }

    &__highlighted,
    &__item:hover {
        background: #e9e9e9;
        transform: scale(1.05);
        transition: all 0.4s;
    }

    a {
        font-size: 28px;
    }

    input {
        padding: 0 0 6px;
        font-size: 18px;
        width: 90%;
    }
}

.orderDropdown,
.searchDropdown {
    position: absolute;
    top: 100%;
    padding: 8px 12px;
    border: 0.5px solid #ededed;
    border-radius: 6px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 8px;
    z-index: 10;

    button {
        color: #a9a7a7;
        padding: 0 4px;
        transition: all 0.4s;
        border-bottom: 1px solid #bababa;
        height: 32px;

        &:hover {
            background: rgba(128, 128, 128, 0.19);
            border-radius: 6px;
            padding: 4px 6px;
            border: none;
        }
    }
}

.orderDropdown {
    left: 16px;
}

.searchDropdown {
    right: 2px;
}

.dropdown-container {
    display: flex;
    justify-content: flex-end;
    width: 10%;
}

.node-title {
    dx: -200;
    fill: green;
}

.node-card {
    width: 300px;
    height: 200px;
}

.hide {
    visibility: "none";
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
