@import "mixins/grid";

$page-navigation-transition: transform 0.5s linear;
$sm-sidenav-width: 200px;

.view-layout {
    .header-content {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 65px;
        z-index: 122;
        display: flex;
        justify-content: space-between;
        padding: 10px 16px;
        background-color: white;

        .landing-nav-links-icon {
            font-size: 36px;
            color: #1e1e1e;
        }

        @include media-gt-phone {
            display: none;
        }
    }
}

.landing-page-wrapper {
    position: relative;
    display: block;
    height: 100vh;
    width: 100vw;
    padding-top: 55px;
    overflow-y: hidden;
    overflow-x: hidden;
    transition: transform 0.2s ease-in;

    &.side-open {
        transform: translateX(-#{$sm-sidenav-width});
    }

    @include media-gt-tablet {
        display: block;
        padding-top: 0;
        transform: translateX(0);
    }
}

.mobile-nav-links-container {
    position: absolute;
    right: -$sm-sidenav-width;
    top: 0;
    bottom: 0;
    width: $sm-sidenav-width;
    height: 100%;
    padding: 0 15px;
    background-color: #000000;
    transition: transform 0.2s ease-in;
    z-index: 9999;
    overflow-y: auto;

    &.side-open {
        transform: translateX(-#{$sm-sidenav-width});
    }

    .close-btn {
        position: absolute;
        left: 0;
        top: 33px;
        height: 2px;
        width: 25px;
        background: white;

        &::after {
            content: "";
            position: absolute;
            right: 0;
            border-right: 2px solid white;
            height: 30px;
            top: 50%;
            transform: translateY(-50%);
        }

        svg {
            color: white;
            font-size: 36px;
        }
    }

    ul.main-links {
        padding-top: 50px;

        @include media-custom-min(375px) {
            padding-top: 80px;
        }

        li {
            color: white;
            font-size: 18px;
            padding: 15px 6px;

            &.divider {
                padding: 0;
                width: 100%;
                border-top: 1px solid var(--gray-400);
                margin: 10px 0;
            }

            .landing-nav-link {
                display: flex;
                gap: 10px;
                color: var(--gray-100);
                align-items: center;

                svg {
                    font-size: 24px;
                }
            }
        }
    }

    ul.bottom-links {
        position: absolute;
        bottom: 12%;
        color: var(--gray-400);
        padding-top: 14px;

        &::before {
            content: "";
            position: absolute;
            top: -2px;
            left: 15px;
            width: 25%;
            border-top: 0.5px solid #ffffff;
        }

        li {
            font-size: 15px;
            padding: 14px;
        }
    }

    .mobile-nav-links-footer {
        position: absolute;
        bottom: 0;
        text-align: center;
        left: 0;
        right: 0;
        padding-bottom: 20px;
        font-size: 12px;
        color: var(--gray-500);
    }

    @include media-gt-tablet {
        display: none;
    }
}
