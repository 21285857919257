path {
    fill: none;
    stroke: #56c2a3;
}

text {
    text-shadow:
        -1px -1px 3px white,
        -1px 1px 3px white,
        1px -1px 3px white,
        1px 1px 3px white;
    pointer-events: none;
    font-family: "SF Pro Display", "Inter", serif;
}

.node {
    fill: #fff;
    stroke: #000;
    transition: all 0.4s;

    // &:hover {
    //   transform: scale(1.02);
    // }
}

.highlightedNode {
    fill: #efefef;
    stroke: #3636ee;
}

.node text {
    font: 12px sans-serif;
}

.node--internal circle {
    fill: #555;
}

.link {
    fill: none;
    stroke: #555;
    stroke-opacity: 0.4;
    stroke-width: 1.5px;
}

.tag-arin {
    background-color: #aeebae;
    padding: 0px 0 2px 14px;
    border-radius: 50px;
}
.tag-clone {
    background-color: #f3f08c;
    padding: 0px 0 2px 14px;
    border-radius: 50px;
}
.tag-sylis {
    background-color: #81d7ee;
    padding: 0px 0 2px 14px;
    border-radius: 50px;
}
.tag-inanimate {
    background-color: #f399ff;
    padding: 0px 0 2px 14px;
    border-radius: 50px;
}
