@import "mixins/grid";

.layout-header {
    position: fixed;
    top: 20px;
    left: 0;
    width: 100vw;
    height: 55px;
    z-index: 1000;

    .header-center {
        position: absolute;
        top: -30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 30px;
        left: 70px;
        right: 70px;

        @include media-gt-phone {
            top: 36%;
            left: 50%;
            right: unset;
            transform: translate(-50%, -50%);
        }

        // gap: 5px;
        .welcome-date {
            color: var(--gray-900);
            font-size: 14px;
            font-weight: 300;
            margin: 0;
            text-align: center;
        }

        .welcome-message {
            color: var(--gray-800);
            font-size: 20px;
            font-weight: 500;
            text-align: center;
            margin-top: 5px;
            line-height: 1;

            @include media-gt-phone {
                font-size: 28px;
            }
        }
    }
}
