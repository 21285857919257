.genealogyDetails-container {
    background: #fff;
    border-radius: 8px;
    box-shadow: 2px 4px 25px gray;
    display: flex;
    flex-direction: column;
    padding: 0 12px 8px;
    min-width: 240px;
    position: absolute;
    top: 3%;
    left: 18%;
    z-index: 1000;

    &__item {
        margin-bottom: 6px;
        display: flex;

        & span:first-of-type {
            font-weight: 600;
            margin-right: 8px;
        }
    }
}

.closeIcon-container {
    width: 100%;
    font-size: 24px;
    display: flex;
    justify-content: flex-end;
}
