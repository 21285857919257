@import "./mixins/grid";

.room-card-container {
    margin-bottom: 14px;

    @include media-gt-phone {
        display: grid;
        grid-template-columns: 50px auto 80px;
    }

    .leading {
        display: none;
        padding-top: 5px;

        img,
        .img-loader {
            border-radius: 50%;
            height: 38px;
            width: 38px;
        }

        @include media-gt-phone {
            display: block;

            &.leading-end {
                display: flex !important;
                justify-content: flex-end;
            }
        }
    }

    .message-box {
        border: 1px solid var(--gray-100);
        border-radius: 8px;
        padding: 20px 14px 12px;
        font-size: 14px;
        font-weight: 300;
        background-color: white;

        strong {
            font-size: 16px;
        }

        .card-response {
            margin-bottom: 8px;

            &:first-child {
                margin: 0;
            }
        }

        .card-response.card-response-link a {
            color: var(--blue-800);

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .trailing {
        display: none;

        @include media-gt-phone {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        svg {
            font-size: 32px;
            color: var(--gray-600);
        }
    }

    .card-footer {
        @include media-gt-phone {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .icon {
            color: var(--gray-700);

            &.positive {
                color: #2196f3;
            }

            &.negative {
                color: #d9214d;
            }
        }

        .sentiment-message {
            display: flex;
            align-items: center;

            .icon {
                font-size: 18px !important;

                &.positive {
                    transform: translateY(-3px);
                }

                &.negative {
                    transform: translateY(3px);
                }
            }
        }

        .card-date {
            font-weight: 300;
            font-size: 12px;
        }

        .card-footer-actions {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            // gap: 10px;
            .sentiment-message {
                font-size: 12px;
            }

            .icon-buttons {
                height: 35px;
                width: 35px;
                padding: 0;
                display: inline-block;
                text-align: center;

                .icon {
                    display: inline;
                    font-size: 20px;

                    &.save-icon {
                        font-size: 24px;
                    }
                }
            }

            .icon {
                font-size: 24px;
                stroke-width: 1px;

                &.rotate {
                    font-size: 16px;
                    transform: rotateZ(45deg);
                }
            }
        }
    }

    &.own-message {
        @include media-gt-phone {
            grid-template-columns: 80px auto 50px;
        }

        .message-box {
            background-color: #f3f9ff;
            border-color: var(--blue-100);
        }

        .leading {
            display: none;
            padding-top: 5px;

            img,
            .img-loader {
                border-radius: 50%;
                height: 38px;
                width: 38px;
            }

            @include media-gt-phone {
                display: block;
            }
        }
    }
}
