.infiniteCanvas {
    position: relative;
    width: 100vw;
    height: 100vh;
    cursor: grab;
    touch-action: none;
    // display: flex;
    // justify-content: center;
    // align-items: center;
}

.infiniteCanvas:active {
    cursor: grabbing;
}

.content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.cardWrapper {
    width: calc(20px * var(--scale-factor));
    height: calc(20px * var(--scale-factor));
}

.checkeredBackground {
    width: 100%;
    height: 100%;
    background-color: white;
    background-image: linear-gradient(
            90deg,
            rgba(200, 200, 200, 0.3) 1px,
            transparent 1px
        ),
        linear-gradient(rgba(200, 200, 200, 0.3) 1px, transparent 1px);
    background-size: calc(20px * var(--scale)) calc(20px * var(--scale));
    transition: background-position 0.3s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;
}

.zoomContainer {
    margin: 0;
    padding: 0;

    transition: transform 0.3s ease-out;
    transform-origin: center center; /* Make sure transformations are based on the center */
}

.infiniteCanvas::-webkit-scrollbar {
    display: none;
    width: 0 !important;
}

.infiniteCanvas {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: -moz-scrollbars-none;
    overflow: hidden;
}

.infiniteCanvasWrapper {
    overflow: hidden;
}

.centerButton {
    position: absolute;
    z-index: 10;
    bottom: 10px;
    right: 10px;
}
